export const TIME_DURATION_PATTERN = /\[?(\d*[.,]?\d+)\s?(minute(?:s)?|min|mn|m|h(?:ou)?r(?:s)?|h|day(?:s)?|d|w(?:ee)?k(?:s)?|w)]?/ig;

/**
 * Takes a duration string and returns the duration in minutes. If the string
 * cannot be parsed return null.
 */
export function parseDuration(value: string): number | undefined {
  const matches = value.matchAll(TIME_DURATION_PATTERN);
  let match = matches.next();
  let duration = 0;

  while (!match.done) {
    const num = Number(match.value[1].replace(/,/g, "."));

    if (typeof num !== "number") return;
    if (typeof num === "number" && num <= 0) return;

    const unit = match.value[2];

    if (unit.startsWith("w")) {
      duration += Math.round(num * 7 * 24 * 60);
    } else if (unit.startsWith("d")) {
      duration += Math.round(num * 24 * 60);
    } else if (unit.startsWith("m")) {
      duration += num
    } else {
      // Assume unit is a hour
      duration += Math.round(num * 60);
    }

    match = matches.next();
  }

  return duration > 0 ? duration : undefined;
}